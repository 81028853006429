.standaloneMedia {
  composes: grid from '~g';
  position: relative;
  width: 100%;
  margin: 0;
}

.mediaWrapper {
  position: relative;
  grid-column: start / end;

  @media (--lg) {
    grid-column: start / end;
  }
}

.media {
  width: 100%;
  height: 100%;
}

.mediaWrapperMobileLeftAligned {
  justify-self: flex-start;

  @media (--md) {
    justify-self: unset;
  }
}

.mediaWrapperMobileRightAligned {
  justify-self: flex-end;

  @media (--md) {
    justify-self: unset;
  }
}

.mediaWrapperMobileCenterAligned {
  width: 100%;
}

.mediaWrapperMobileLeftAligned,
.mediaWrapperMobileRightAligned {
  composes: deviceMediaContainer from '~/components/atoms/DeviceWrapper/DeviceWrapper.module.css';
}
