.stickyBackground {
  position: relative;

  /* Disable hidden from the garage door because of position sticky */
  /* stylelint-disable-next-line declaration-no-important */
  overflow: unset !important;
  opacity: 1;

  --rounded-bottom-padding: 0;

  @media (--lg) {
    margin-top: calc(var(--spacer-64) * -1);
  }

  .innerContainer {
    position: relative;
  }

  .background,
  .foreground {
    position: relative;
    top: 0;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 1.25;
    background: transparent;
    pointer-events: none;

    @media (--md) {
      position: sticky;
      z-index: var(--z-index-2);
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      margin-left: 0;
    }
    @media (--lg) {
      right: 0;
      left: auto;
      padding-left: min(8vw, 70rem);
      margin: 0 auto;
    }
    @media (--xl) {
      padding-left: min(20vw, 60rem);
    }

    .backgroundWrapper,
    .foregroundWrapper {
      --scale: 1;
      position: absolute;
      left: 50%;
      width: calc(100% * var(--scale));
      height: calc(100% * var(--scale));
      transform: translate(-50%, 0) scale(2);

      /* Made the positioning of the background & video relative to center so
      that both placement are synced - and only need to scale/translate parent */
      @media (--md) {
        --scale: 2.1;
        top: 50%;

        /* Have to put on both, because Background also have TV, so it doesn't work */
        transform: translate3d(calc(40% / var(--scale) - 50%), calc(-10% / var(--scale) - 50%), 0);
      }

      @media (--lg) {
        /* fixed width for bigger screen size as per design request */
        width: calc(144rem * var(--scale));
        transform: translate(calc(25% / var(--scale) - 50%), calc(-10% / var(--scale) - 50%));

        --scale: 1.45; /* scale by 40% so that TV screen takes approx. 60% of the screen - calc based on comp */
      }
    }
  }

  .background {
    z-index: var(--z-index-1);
    padding-top: var(--spacer-96);

    @media (--md) {
      padding-top: 0;
    }

    .backgroundGlow {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 42%; /* video size in relation to image size */
      aspect-ratio: 16/9;
      transform: translate(-50%, -50%);

      @media (--lg) {
        transform: translate(-50%, -32%);
      }
    }
  }

  .foreground {
    position: absolute;
    z-index: var(--z-index-3);
    @media (--md) {
      position: sticky;
      margin-top: -100vh;
    }

    .foregroundImage {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      width: 233.01%; /* scale it up */
      height: auto;
      margin-top: -0.06rem; /* make room for TV border */
      transform: translate(-50%, -50%);

      @media (--md) {
        margin-top: -0.1rem;
      }
    }

    .mediaWrapper {
      position: absolute;
      z-index: var(--z-index-2);
      top: 50%;
      left: 50%;
      width: 45.3%; /* video size in relation to image size */
      transform: translate(-50%, -50%);

      @media (--md) {
        width: 42%; /* video size in relation to image size */
        transform: translate(-50%, -50%);
      }
    }

    .media {
      position: relative;
      z-index: var(--z-index-1); /* Make it go in front TV */
    }
  }
}

.container {
  composes: grid from '~g';
  padding-top: var(--spacer-40);
  padding-bottom: var(--spacer-64);
  row-gap: var(--gap);

  --gap: var(--spacer-40);

  @media (--md) {
    --gap: calc(2 * var(--spacer-124));
    padding-top: 0;

    /* align top of last text section to the vertical center of TV, then off set it with half the height of the section to center vertically */
    padding-bottom: calc(50vh - (var(--last-section-height) - 10rem));

    /* to compensate background sticky 100vh */
    margin-top: -100vh;
  }

  @media (--lg) {
    /* calculated based on current TV screen position in relation to screen sizes */
    --gap: max(13vw, 36vh);
  }
}

.section {
  composes: subgrid from '~g';

  --subgrid-start: start;
  align-items: center;
  align-self: center;

  @media (--md) {
    /* align top of text content to the vertical center of TV */
    &:first-child {
      margin-top: calc(50vh - 10rem);

      /* then add negative margin to align vertical center of first section to the center of the TV */
      & > :first-child {
        margin-top: calc(-1 * var(--first-section-height));
      }
    }
  }
}

.sectionContentTouts {
  grid-column: start / end;
  @media (--md) {
    grid-column: start / span 4;
  }
}
