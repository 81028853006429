.deviceWrapper {
  position: absolute;
  z-index: var(--z-index-neg);
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
}

.deviceScreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.appleStudioDisplayStand {
  position: absolute;
  z-index: var(--z-index-neg);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.iphone {
  position: absolute;
  z-index: var(--z-index-neg);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ipad {
  position: absolute;
  z-index: var(--z-index-neg);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* studio display screen borders */
.withAppleStudioDisplayBorders {
  padding: 1.9% 2% 2%;
}

.macbookPro {
  position: absolute;
  z-index: var(--z-index-neg);
  top: -2.2%;
  left: -4.5%;
  width: 109%;
}

/* MBP screen borders */
.withMacbookProBorders {
  padding: 0 1.5%;
  margin-top: 1.35%;
  margin-bottom: 8%;
}

.macbookProReflection {
  position: absolute;
  z-index: var(--z-index-neg);
  top: 105%;
  left: -30%;
  width: 155%;
}

/* positions media containers that are wrapped in device wrapper partially offscreen for small breakpoint */
.deviceMediaContainer {
  width: min(166%, 53rem);

  @media (--md) {
    width: auto;
  }
}
